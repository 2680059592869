import { FC, Dispatch, useReducer, createContext } from "react";

export interface MapPageContextState {
  isMapMenuOpen: boolean;
  isSmallScreenWidth: boolean;
}

export const initialState: MapPageContextState = {
  isMapMenuOpen: false,
  isSmallScreenWidth: false,
};

export enum MapPageContextActionTypes {
  SET_IS_MAP_MENU_OPEN = "SET_IS_MAP_MENU_OPEN",
  SET_IS_SMALL_SCREEN_WIDTH = "SET_IS_SMALL_SCREEN_WIDTH",
}

type MapPageContextActionsPayload = {
  [MapPageContextActionTypes.SET_IS_MAP_MENU_OPEN]: boolean;
  [MapPageContextActionTypes.SET_IS_SMALL_SCREEN_WIDTH]: boolean;
};

export type MapPageContextActions =
  ActionMap<MapPageContextActionsPayload>[keyof ActionMap<MapPageContextActionsPayload>];

export const mapPageContextReducer = (
  state: MapPageContextState,
  action: MapPageContextActions
): MapPageContextState => {
  switch (action.type) {
    case MapPageContextActionTypes.SET_IS_MAP_MENU_OPEN: {
      return { ...state, isMapMenuOpen: action.payload };
    }
    case MapPageContextActionTypes.SET_IS_SMALL_SCREEN_WIDTH: {
      return { ...state, isSmallScreenWidth: action.payload };
    }
    default:
      return state;
  }
};

export const MapPageContext = createContext<{
  mapPageContextState: MapPageContextState;
  mapPageContextDispatch: Dispatch<MapPageContextActions>;
}>({
  mapPageContextState: initialState,
  mapPageContextDispatch: () => undefined,
});

export const MapPageContextProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(mapPageContextReducer, initialState);

  return (
    <MapPageContext.Provider
      value={{ mapPageContextState: state, mapPageContextDispatch: dispatch }}
    >
      {children}
    </MapPageContext.Provider>
  );
};
